<template>
    <v-row class="d-flex justify-center mt-0">
      <v-col cols="12">
        <v-expansion-panels v-model="panel">
          <v-expansion-panel readonly>
            <v-btn
              style="position: absolute; top: -15px; right: 55px; z-index: 1"
              color="success"
              small
              fab
              @click="open_dialog(null)"
            >
              <v-icon>fas fa-plus</v-icon>
            </v-btn>
            <v-expansion-panel-header class="py-0 px-4 text-h5" style="cursor: default">
              <div>
                <v-icon class="mb-1" left>fas fa-filter</v-icon>
                Filtro
              </div>
              <template v-slot:actions>
                <v-btn icon @click.stop="panel = panel === 1 ? 0 : 1">
                  <v-icon>fas fa-chevron-down</v-icon>
                </v-btn>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="pt-1">
              <v-form @submit.prevent="buscar()">
                <v-row :no-gutters="$vuetify.breakpoint.xs">
                  <v-col cols="12" sm="6" md="3" class="py-1">
                    Nombre
                    <v-text-field
                      v-model.trim="filtro.nombre"
                      tabindex="1"
                      hide-details
                      autofocus
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <!-- <v-col cols="6" md="2" class="d-flex justify-center aling-center py-1">
                    <v-switch
                      v-model="filtro.inhabilitada"
                      label="Inhabilitada"
                      class="mb-md-2"
                      tabindex="1"
                      :true-value="1"
                      :false-value="0"
                      hide-details
                      dense
                    ></v-switch>
                  </v-col> -->
                  <BtnFiltro
                    :loading="load"
                    @clear="limpiar()"
                  />
                </v-row>
              </v-form>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        
        <v-data-table
          class="cebra elevation-2 mt-2"
          :headers="headers"
          :items="tamanios"
          :loading="load"
          :search="search"
          sort-by="nombre"
          dense
        >
        <template v-slot:top>
          <v-row class="d-flex justify-end pa-2" no-gutters>
            <v-col cols="6" sm="3" xl="2">
              <SearchDataTableVue
                v-model="search"
              />
            </v-col>
          </v-row>
        </template>
          <template v-slot:[`item.inhabilitada`]="{ item }">
            <span
              v-text="item.inhabilitada ? 'SI' : 'NO'"
            ></span>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon
              v-if="!item.inhabilitada"
              color="primary"
              title="Editar"
              class="mr-2"
              small
              @click="open_dialog(item)"
            >
              fas fa-pen
            </v-icon>
            <BtnConfirmar
              :icono="item.inhabilitada ? 'fas fa-check' : 'fas fa-ban'"
              :color="item.inhabilitada ? 'success' : 'error'"
              :title="item.inhabilitada ? 'Habilitar' : 'Inhabilitar'"
              :texto="item.inhabilitada
                ? `¿Desea habilitar la marca <strong>${item.nombre}</strong>?`
                : `¿Desea inhabilitar la marca <strong>${item.nombre}</strong>?`"
              :icon_button="true"
              :small="true"
              @action="item.inhabilitada ? habilitar(item) : inhabilitar(item)"
            />
          </template>
          <template v-slot:no-data>
            <v-alert
              class="mx-auto mt-4"
              max-width="400"
              type="warning"
              border="left"
              dense
              text
            >
              No hay datos para los filtros seleccionados
            </v-alert>
          </template>
        </v-data-table>
      </v-col>
      <Tamanio
        v-model="dialog"
        :p_nuevo="nuevo"
        :p_tamanio="tamanio"
        @editar="editar"
      />
    </v-row>
  </template>
  
  <script>
  import BtnFiltro from '../../components/util/BtnFiltro'
  import BtnConfirmar from '../../components/util/BtnConfirmar'
  import Tamanio from '../../components/generales/Tamanio'
  import SearchDataTableVue from '../../components/util/SearchDataTable.vue'
  
  export default {
    name: 'Tamanios',
    data () {
      return {
        panel: 0,
        search: '',
        load: false,
        nuevo: false,
        dialog: false,
        tamanios: [],
        headers: [
          { text: 'Código', value: 'codigo', align: 'end' },
          { text: 'Nombre', value: 'nombre' },
          { text: 'Inhabilitada', value: 'inhabilitada', sortable: false, filterable: false },
          { text: 'Acciones', value: 'actions', sortable: false, filterable: false },
        ],
        tamanio: {
          codigo: null,
          abreviacion: null,
          nombre: null
        },
        filtro: {
          nombre: '',
          inhabilitada: 0
        }
      }
    },
    components: {
      BtnFiltro,
      BtnConfirmar,
      Tamanio,
      SearchDataTableVue
    },
    methods: {
      async habilitar (item) {
        this.$store.state.loading = true
        await this.$store.dispatch('tamanios/habilitar', item.codigo)
          .then((res) => {
            // actualiza la marca en la lista
            item.inhabilitada = 0
            // muestra mensaje
            this.$store.dispatch('show_snackbar', {
              text: res.message,
              color: 'success',
            })
          })
          .catch(error => {
            this.$store.dispatch('show_snackbar', {
              text: error.message,
              color: 'error',
            })
          })
        this.$store.state.loading = false
      },
      async inhabilitar (item) {
        this.$store.state.loading = true
        await this.$store.dispatch('tamanios/inhabilitar', item.codigo)
          .then((res) => {
            // actualiza la marca en la lista
            item.inhabilitada = 1
            // muestra mensaje
            this.$store.dispatch('show_snackbar', {
              text: res.message,
              color: 'success',
            })
          })
          .catch(error => {
            this.$store.dispatch('show_snackbar', {
              text: error.message,
              color: 'error',
            })
          })
        this.$store.state.loading = false
      },
      async buscar (){
        this.load = true
        this.search = ''
        this.tamanios = []
        const res = await this.$store.dispatch('tamanios/get_tamanios', this.filtro)
        if(res.resultado == 0){
          return this.$store.dispatch('show_snackbar', { text: error.message, color: 'error', });
        }  
        this.tamanios = res.data;
        this.load = false
      },
      editar (tamanio) {
        // actualiza el nombre de tamanio en la lista
        this.tamanios.find(t => t.codigo == tamanio.codigo).nombre = tamanio.nombre.toUpperCase()
      },
      open_dialog (item) {
        if (item) {
          this.nuevo = false
          this.tamanio = JSON.parse(JSON.stringify(item))
        } else {
          this.nuevo = true
          this.tamanio = {
            codigo: null,
            abreviacion: null,
            nombre: null
          }
        }
        this.dialog = true
      },
      limpiar () {
        this.filtro = {
          nombre: '',
          inhabilitada: 0
        }
      }
    }
  }
  </script>